/* =============================== NAVBAR =================================================== */


/* =============================== FOOTER =================================================== */
.qs-footer-header {
    color: #000 !important;
    font-weight: 500 !important;
}

/* =============================== VERIFICATION PAGE =================================================== */
.qs-verification-container {
    background: linear-gradient(93deg, #FEEBCF 1.06%, #FDCBC1 100.44%);
}

.tt-verification-card {
    max-width: 600px;
    box-shadow: 10px 10px #000;
}

.tt-verification-card>h3 {
    font-family: 'Lexend', sans-serif;
}


/* =============================== HOME =================================================== */
.qs-home-hero-bg {
    background: radial-gradient(64.05% 51.1% at 50% 25.36%, rgba(253, 203, 193, 0.90) 9%, #FEEBCF 15.38%, rgba(255, 255, 255, 0.00) 50.25%);
}
/* .qs-home-hero-bg {
    background: radial-gradient(64.05% 51.1% at 50% 41.36%, rgba(253, 203, 193, 0.90) 0%, #FEEBCF 15.38%, rgba(255, 255, 255, 0.00) 50.25%);
} */

.qs-home-serviceone {
    height: 450px !important;
    max-width: 300px !important;
    background: linear-gradient(142deg, #FDE7EA -0.96%, #FDEFDA 100%);
}

.qs-home-servicetwo {
    height: 450px !important;
        max-width: 300px !important;
    background: linear-gradient(218deg, #FFF1EB 0%, #F7F5CA 100.28%);
}

.qs-home-servicethree {
    height: 450px !important;
        max-width: 300px !important;
    background: linear-gradient(90deg, #F6F9DB 0.1%, #D9F9E8 99.49%);
}

.qs-home-servicefour {
    height: 450px !important;
        max-width: 300px !important;
    background: linear-gradient(90deg, #D9F9E8 0.1%, #D9F9E8 99.49%);
}

.qs-home-list {
    min-height: 450px !important;
    background: #F7F5CA;
}

.qs-home-selling-points {
    height: 400px;
    max-width: 300px !important;
    background: linear-gradient(135deg, rgba(253, 203, 193, 0.90) -0.02%, #F7F5CA 99.98%);
}

.qs-home-next-steps {
    background: linear-gradient(93deg, #FEEBCF 1.06%, #FDCBC1 100.44%);
}

.qs-swiper-size {
    max-width: 1100px;
    /* centers swiper slide */
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    filter: blur(4px);
}

.swiper-slide-active {
    filter: blur(0);
}



/* =============================== SERVICES =================================================== */
.qs-services-service-one {
    background: #FFF1EB;
    /* padding: 20rem !important; */
}

.qs-services-service-two {
    background: #FFE8CE;
}

.qs-services-service-three {
    background: #FED2C4;
}

.qs-services-service-four {
    background: #F7F5CA;
}

.qs-bullet-points {
    list-style-image: url(../src/assets/carrot.svg);
}

ul {
    list-style-image: url('../src/assets/check.svg');
    list-style-position: outside;
}

/* .qs-home-bullet-points {
    list-style-position: inside !important;
}

@media (max-width:992px) {
.qs-home-bullet-points {
        list-style-position: outside !important;
    }
} */


/* =============================== ABOUT =================================================== */

.qs-about-what-we-do {
    background-color: rgba(255, 255, 255, 0.70) !important;
    background-image: url(../src/assets/aboutus.png);
    height: 70vh;
    max-height: 70vh;
        background-position: center;
        background-repeat: no-repeat;
}

.qs-about-cards {
    height: 200px !important;
    max-width: 400px !important;
    background: linear-gradient(90deg, #F6F9DB 0.1%, #D9F9E8 99.49%);
}

.qs-about-selling-points {
    font-size: 4.5rem;
}

.qs-about-written-content {
    max-width: 30rem !important;
}

/* =============================== CONTACT =================================================== */

.qs-contact-form {
    background: linear-gradient(244deg, #FFF1EB 1.02%, #F7F5CA 100.97%);
}

.qs-contact-form div {
    width: 50vw !important;
    max-width: 610px !important;
}

.qs-contact-statement {
    background: linear-gradient(90deg, #F6F9DB 0.1%, #D9F9E8 99.49%);
}

.qs-contact-info {
    background: linear-gradient(142deg, #FDE7EA -0.96%, #FDEFDA 100%);
    min-height: 4rem !important;
}

@media (max-width: 419px)  {
.qs-contact-btn-size {
        font-size: 0.6rem !important;
    }
}

@media (max-width: 460px) and (min-width:420px) {
.qs-contact-btn-size {
        font-size: 0.8rem !important;
    }
}

/* .qs-contact-half {
    max-height: 300px !important;
} */

/* */


/* @media (min-width: 1400px) {
    .qs-contact-half {
        max-width: 40rem !important;
    }
} */


/* =============================== COMPONENTS =================================================== */
.qs-button-main {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 2px black;
    background-color: transparent;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    color: black !important

}

/* * {
    outline: solid red 1px;
} */

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lexend&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url("https: //use.typekit.net/rsu4pyh");

/* Colors */
:root {
    --color-default: #FCAB68;
    --color-pink : #FAE1E8;
    --color-brown: #E9D3C1;
    --color-light-green: #EEF9CF;
    --color-mint-green: #BFE4C7;
    --color-hue-pink: #FFDCDC;
    --color-hue-peach: #FFD1C2;
    --color-peach: #FFF4EB;

}

/* Smooth Scroll */
:root {
    scroll-behavior: smooth;
}

/* =============================== GENERAL =================================================== */
body {
    /* font-family: 'Poppins', sans-serif !important; */
    font-family: 'bebas-neue-pro', sans-serif !important;
    padding-top: 100px;
}

/* section {
    margin-bottom: 7.5em;
} */

a:hover {
    color: var(--color-default) !important;
}

span {
    color: var(--color-default);
}

img {
    border-radius: 7.5px !important;
}

.tt-button {
    border: solid 2px !important;
    border-radius: 6px;
    box-shadow: 5px 5px #000;
}

a .tt-button:hover {
    border: solid black 2px !important;
    color: black !important;
}

@media (min-width:992px) {
    .tt-header-fs {
        font-size:80px;
    }
    .tt-header-fs-slogan {
        font-size: 63px !important;
    }
    .tt-header-hero-fs {
        font-size: 85px !important;
    }
}

@media (max-width: 1199px) {
    .tt-header-fs {
        font-size:40px;
    }
    .tt-header-fs-slogan {
        font-size: 63px !important;
    }
    .tt-header-hero-fs {
        font-size: 45px !important;
    }
}

/* @media (min-width:993px) and (max-width:1199px){
    .tt-header-fs {
            font-size: 40px;
        }
    
        .tt-header-fs-slogan {
            font-size: 63px !important;
        }
    
        .tt-header-hero-fs {
            font-size: 45px !important;
        }
} */

p {
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 18px !important;
}

a {
    font-size: 18px !important;
}




/* =============================== NAVIGATION =================================================== */

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.nav-link.active {
    /* color: var(--color-default) !important; */
}

.tt-logo {
    position: relative;
}

.tt-logo::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 5px;
        bottom: -5px;
        width: 100%;
        height: 100%;
        border-radius: 3.25px;
        border: 1px solid var(--color-default);
}



/* =============================== VERIFICATION PAGE =================================================== */
.tt-verification-container {
    background: linear-gradient(180deg, #FCC168 0%, #FCAB68 100%);
}

.tt-verification-card {
    max-width: 600px;
    box-shadow: 10px 10px #000;
}

.tt-verification-card>h3 {
    font-family: 'Lexend', sans-serif;
}




